export const TechnologyMax = {
    partner: [
      {
        "orgName": "AbitOs",
        "link": "http://abitos.com/",
        "name":"Raimundo Lopez-Lima Levi, Rosangela De Morais",
        "email":"ray@abitos.com",
        "phone": "",
        "comments":"",
        "keywords":["Real Estate", "Income Tax", "Tax Analytics", "Foreign Ownership", "Process", "Documentation", "finance", "On-Line applications", "Packaging",  "Pro Bono"]
      },
        {
        "orgName": "Akerman",
        "link": "https://www.akerman.com/en/",
        "name":"Gina DelChiaro",
        "email":"gina.delchiaro@akerman.com",
        "phone": "",
        "comments":"",
        "keywords":["Real Estate", "Income Tax", "Tax Analytics", "Foreign Ownership", "Process", "Documentation", "finance", "On-Line applications", "Packaging",  "Pro Bono"]
      },
        {
        "orgName": "Arts & Business Council of Miami",
        "link": "https://www.artsbizmiami.org/programs",
        "name":"",
        "email":"info@artsbizmiami.org",
        "phone": "",
        "comments":"",
        "keywords":["Art Business", "Miami", "Art", "rtists", "Lawyer", "Volunteer", "Pro Bono"]
      },
      {
        "orgName": "Gray Robinson",
        "link": "http://www.gray-robinson.com/offices/miami-fl",
        "name":"Peter Quinter",
        "email":"peter.quinter@gray-robinson.com",
        "phone": "",
        "comments":"",
        "keywords":["International Trade", "Legal", "Compliance", "Regulatory", "Governmental Relations",  "Pro Bono"]
      },
      {
        "orgName": "Marcum Accountants & Advisors",
        "link": "https://www.marcumllp.com/offices/miami-florida",
        "name":"Lewis Greeenberg, Francillon Anael",
        "email":"Lewis.Greenberg@marcumllp.com",
        "phone": "",
        "comments":"",
        "keywords":["Food & Beverage", "Maritime", "Industry Perspective", "Income Tax", "Tax Analytics", "QuickBooks Support",  "Pro Bono"]
      },
      {
        "orgName": "MBAF - Morrison, Brown, Argiz & Farra ",
        "link": "https://www.bdo.com/about/us-locations/miami-office",
        "name":"Hernando Gomez",
        "email":"hgomez@mbafcpa.com",
        "phone": "",
        "comments":"",
        "keywords":["Business Valuation", "legal", "Cashflow", "Budget Analysis", "Bankruptcies", "Financial records", "Pro Bono"]
      },
      {
        "orgName": "The Victoria Law Group",
        "link": "https://www.thevictorialawgroup.com/",
        "name":"Bob Arnold, Bruce Bennett",
        "email":"bob@thevictorialawgroup.com",
        "phone": "",
        "comments":"",
        "keywords":["Immigration", "International", "Brazil", "Real Estate", "Portuguese Government Contracting", "Insurance", "Pro Bono"]
      }
    ],
  }
  
  export default TechnologyMax
  
